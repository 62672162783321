/********** Template CSS **********/
$theme-colors: (
  "primary": #fea116,
  // "danger": #ff4136,
  "dark": #0f172b,
  "light": #f1f8ff,
  "secondary": #6c757d,
);
.container {
  max-width: unset !important;
}

@import "~bootstrap/scss/bootstrap.scss";

body {
  font-family: "Lato", sans-serif;
  line-height: 1.5;
}

.App {
  overflow: hidden;
}
:root {
  --primary: #fea116;
  --light: #f1f8ff;
  --dark: #0f172b;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  height: 7rem;
  z-index: 10;
}

.ma-30 {
  margin: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.font-14 {
  font-size: 14px;
}

.service-content-wrapper {
  max-height: 90px;
  height: 90px;
}
.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}
.calc-container {
  background-color: #f5f6f7;
}

.flex-container {
  display: flex;
  align-items: center;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

.floating-contacts {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 95px;
  z-index: 99;
  color: #fea116;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 2px;
}

/*** Navbar ***/

.navbar-dark .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  // color: #ffffff;
  color: #6c757d;
  font-size: 15px;
  text-transform: uppercase;
  outline: none;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--primary);
}

.nav-link:hover {
  color: var(--primary) !important;
}
.active {
  .nav-link {
    color: var(--primary) !important;
  }
}

.navbar-toggler {
  background-color: #fea116;
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }
}

/*** Header ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(15, 23, 43, 0.7);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}

.carousel-item {
  max-height: 900px;
}

@media (max-width: 768px) {
  .calc-button {
    margin-top: 10px;
  }
  .font-homo {
    font-size: 25px !important;
  }
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    height: 4.5rem;
    z-index: 10;
  }

  #home .carousel-item {
    position: relative;
    min-height: 450px;
  }

  #home .carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-header {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-header-inner {
  background: rgba(15, 23, 43, 0.7);
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

.booking {
  position: relative;
  margin-top: -100px !important;
  z-index: 1;
}

/*** Section Title ***/
.section-title {
  position: relative;
  display: inline-block;
}

.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  left: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.section-title::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  right: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.section-title.text-start::before,
.section-title.text-end::after {
  display: none;
}

/*** Service ***/
.service-item {
  height: 330px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  background: #ffffff;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
  color: #fea116;
  text-decoration: none;
}

.service-item:hover {
  background: var(--primary);
}

.service-item .service-icon {
  margin: 0 auto 30px auto;
  width: 65px;
  height: 65px;
  transition: 0.5s;
}

.service-item i,
.service-item h5,
.service-item p {
  transition: 0.5s;
}

.service-item:hover i,
.service-item:hover h5,
.service-item:hover p {
  color: #ffffff !important;
}

/*** Youtube Video ***/
.video {
  position: relative;
  height: 100%;
  min-height: 500px;
  /* background: linear-gradient(rgba(15, 23, 43, .1), rgba(15, 23, 43, .1)), url(../img/video.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video .btn-play {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

.video .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

#videoModal {
  z-index: 99999;
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #ffffff;
  background: #000000;
  opacity: 1;
}

/*** Testimonial ***/
.testimonial {
  /* background: linear-gradient(rgba(15, 23, 43, .7), rgba(15, 23, 43, .7)), url(../img/carousel-2.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial-carousel {
  padding-left: 65px;
  padding-right: 65px;
}

.testimonial-carousel .testimonial-item {
  padding: 30px;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 40px;
  top: calc(50% - 20px);
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: var(--primary);
  border-radius: 2px;
  font-size: 18px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: var(--primary);
  background: #ffffff;
}

/*** Team ***/
.team-item,
.team-item .bg-primary,
.team-item .bg-primary i {
  transition: 0.5s;
}

.team-item:hover {
  border-color: var(--secondary) !important;
}

.team-item:hover .bg-primary {
  background: var(--secondary) !important;
}

.team-item:hover .bg-primary i {
  color: var(--secondary) !important;
}

/*** Footer ***/
.newsletter {
  position: relative;
  z-index: 1;
}

.footer {
  position: relative;
  padding-top: 20px;
}

.footer-link {
  display: inline-block;
  padding-top: 6px;
  padding-bottom: 6px;
  // color: rgba(255, 255, 255, 0.6);
  color: #fff;
  transition: all 0.2s ease;
  text-decoration: none;
}

.footer-menu a:hover,
.footer-link:hover {
  text-decoration: none;
  color: #fea116;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid #ffffff;
  border-radius: 35px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
.scroll-to-top {
  background-color: white;
  right: 40px;
  bottom: 40px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
  border-radius: 7px;
  width: 40px;
  height: 40px;
  box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
  border: none;
}

.scroll-to-top:active {
  transform: matrix(0.95, 0, 0, 0.95, 0, 0);
}

.custom-modal-footer {
  border-top: 0 !important;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.step-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  h3 {
    margin-bottom: 20px;
  }
}

.step-1-cards {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.bordershadow:hover {
  box-shadow: 0 0 6px rgb(35 173 255);
}

.selected {
  border: 2px solid #5b6b7a;
}

.boxshadow-default {
  box-shadow: 0 0 6px rgb(121, 130, 136);
}

.boxshadow-default:hover {
  box-shadow: 0 0 6px rgb(35 173 255);
}

.margin-top-bottom {
  margin: 10px 0;
}

#contact .form-control {
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 0%;
}

.form-select {
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 0%;
}

.form-select:focus {
  box-shadow: none;
}
.form-control:focus {
  box-shadow: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 400px;
  position: relative;
  overflow: hidden;
}

#gallery {
  .item {
    text-align: center;
    padding: 50px;
    margin-bottom: 0px;
    opacity: 0.2;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
  }
}
#gallery .owl-item.active.center .item {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.hasError {
  border: 1px solid #ef0c58 !important;
}

.font-arial {
  font-family: "Arial Black";
}

.project-card-container {
  padding: 20px;
}

.project-card {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 240px;
  p{
    width: 90%;
    font-weight: 700;
  }
}

.project-img {
  height: 130px;
  width: 240px;
  margin-bottom: 30px;
  box-shadow: none;
  padding: 10px;
  cursor: pointer;
}
.project-desc :hover,
.project-img :hover {
  box-shadow: 0 !important;
}
.project-desc {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

.vl {
  border-left: 6px solid green;
  height: 500px;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}
